<template>
  <div>
    <el-form :rules="rules"
             v-loading="loading"
             ref="eventCardForm"
             :model="eventCard"
             label-width="15rem">
      <el-row :gutter="20" class="mt-0">
        <el-col :span="12">
          <el-row :gutter="15" class="mt-1">
            <el-col :span="10" class="mt-2">
              <el-form-item label="Номер события:" prop="number">
                <el-input size="small"
                          disabled
                          v-model="eventCard.number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14" class="mt-2">
              <el-form-item label="Дата и время события:" prop="eventDateTime">
                <el-date-picker type="datetime"
                                v-model="eventDate"
                                v-mask="'##.##.#### ##:##'"
                                size="small"
                                :disabled="!editMode"
                                placeholder="дд.мм.гггг чч:мм"
                                format="dd.MM.yyyy HH:mm"
                                style="width: 100%"
                                :picker-options="datePickerOptions"/>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="userHasRoleGroup('ExternalUser')"
                    style="margin-bottom:-0.5rem">
              <el-form-item label="Требует корректировки:" prop="needCorrect">
                <el-checkbox :disabled="!editMode || !userHasRole('IncidentsEdit')"
                             v-model="eventCard.needCorrect"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="eventCard.incidentNumber" :gutter="15" class="mt-0">
            <el-col :span="10">
              <el-form-item label="Номер аварии:" prop="incidentNumber">
                <router-link :to="{ name: 'IncidentItem', params: { id: eventCard.incidentId } }">
                  <el-link type="primary">{{ eventCard.incidentNumber }}</el-link>
                </router-link>
                <el-button v-if="userHasRole('IncidentsEdit')" class="ml-2" size="small"
                           icon="el-icon-edit" @click="changeIncidentId = true" circle
                           :disabled="!editMode"></el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="eventCard.primaryEquipmentId" :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Первичное оборудование/ЛЭП:" prop="primaryEq">
                <el-input size="small"
                          disabled
                          v-model="eventCard.primaryEquipmentName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="eventCard.secondEquipmentPanelId" :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Устройство РЗА:" prop="secondEquipmentName">
                <el-input size="small"
                          disabled
                          v-model="eventCard.secondEquipmentPanelName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Энергообъект:" prop="energyObjectName">
                <el-input size="small"
                          disabled
                          v-model="eventCard.energyObjectsName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="eventCard.primaryEquipmentId" :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Напряжение:" prop="baseVoltageName">
                <el-input size="small"
                          v-if="eventCard.baseVoltageName"
                          disabled
                          v-model="eventCard.baseVoltageName"
                          style="max-width: 150px"></el-input>
                <span v-if="!eventCard.baseVoltageName">Не указано</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="showEventMapMsg" :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-card>
                <el-row class="my-label-14">
                  <el-col :span="6" style="padding: 12px 0">Сопоставление событий</el-col>
                  <el-col :span="6">
                    <el-button type="text" icon="el-icon-refresh" @click="loadMap"></el-button>
                  </el-col>
                </el-row>
                <el-row class="my-label-14">
                  <el-col :span="6" style="padding: 12px 0">Найдено события для сопоставления:
                  </el-col>
                  <el-col :span="6">
                    <el-button type="text" @click="handleToMap">{{ eventMap.length }}</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>

          <el-row v-if="eventCard.secondEquipmentPanelId" :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Фирменное устройство РЗА:" prop="productAssetModelName">
                <el-input size="small"
                          disabled
                          v-model="eventCard.productAssetModelName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row :gutter="10" class="mt-0">
            <el-col :span="12">
              <el-form-item label="Автор:" prop="userName">
                <el-input size="small"
                          disabled
                          v-model="eventCard.userName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Источник:" prop="sourceName">
                <el-input size="small"
                          disabled
                          v-model="eventCard.sourceName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="eventCard.correlatedEvents" :gutter="15" class="mt-0">
            <el-col v-if="eventCard.correlatedEvents.length!=0" :span="24">
              <el-form-item label="Событие сопоставления:" prop="correlatedEvents">
                <div class="cell">
                  <div class="entity-view">
                    <div style="display: block;"
                      v-for="(corEvent, key) in eventCard.correlatedEvents" :key="key" @click="handleOpenCor(corEvent.id)">
                      <span class="el-link--inner el-link el-link--primary is-underline" >{{ corEvent.statusName }}</span>
                      <!-- <span class="el-link--inner"   >№{{ corEvent.statusName }}</span> -->
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row v-if="eventCard.correlationId" :gutter="15" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Событие сопоставления:" prop="correlatedEvents">
                <div class="cell"><div class="entity-view">
                  <div style="display: block;" class="el-link el-link--primary is-underline" @click="handleOpenCor(eventCard.correlationId)">
                    <span class="el-link--inner">{{ eventCard.correlationStatusName }}</span></div>
                </div></div>
              </el-form-item>
            </el-col>
          </el-row> -->
          <!--          <el-row :gutter="10" class="mt-0">
                      <el-col :span="24">
                        <el-form-item label="Описание события:"
                                      prop="description"
                                      v-loading="loading">
                          <el-input size="small"
                                    v-model="eventCard.description"
                                    type="textarea"
                                    :rows="15"
                                    :disabled="!editMode"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>-->
        </el-col>
        <el-col :span="12">
          <el-row :gutter="10" class="mt-1">
            <el-col :span="24" class="mt-2">
              <el-form-item prop="eventTypeId" label="Тип события:">
                <el-select popper-class="search-select" size="small" clearable
                           v-model="eventCard.eventTypeId" placeholder=""
                           :disabled="!editMode"
                           >
                  <el-option v-for="item in eventTypes"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15" class="mt-0">
            <el-col :span="24">
              <el-form-item prop="shortCircuitForm" label="Вид КЗ:">
                <el-select popper-class="search-select" size="small" clearable
                           v-model="eventCard.shortCircuitFormId" placeholder=""
                           @change="onChangeFaultedPhases"
                           :disabled="!editMode">
                  <el-option v-for="item in shortCircuitForms"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Место КЗ:">
                <el-select popper-class="search-select" size="small" clearable
                           v-model="eventCard.shortCircuitPlaceId" placeholder=""
                           :disabled="!editMode">
                  <el-option v-for="item in shortCircuitPlaces.filter(t => t.actual)"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Поврежденные фазы:">
                <el-select popper-class="search-select" size="small" clearable
                           v-model="eventCard.faultedPhases" :disabled="!editMode" multiple
                           :multiple-limit="faultedPhasesLimit"
                           placeholder="">
                  <el-option v-for="item in faultedPhases"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Доп. информация:">
                <el-select multiple v-model="eventCard.cardInformation" :disabled="!editMode"
                           size="large">
                  <el-option
                    v-for="item in cardInformations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.isDisabled"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Описание события:"
                            prop="description"
                            v-loading="loading">
                <el-input size="small"
                          v-model="eventCard.description"
                          type="textarea"
                          :rows="12"
                          :disabled="!editMode"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="Перепривязка события"
               :visible.sync="changeIncidentId"
               width="30%"
               :close-on-click-modal="false">
      <el-row>
        <span>Выберите аварию, к которой необходимо привязать данное событие:</span>
      </el-row>
      <el-row class="mt-1">
        <!--        <el-select filterable v-model="newIncidentId">
                  <el-option v-for="item in incidentsList"
                             :key="item.id"
                             :label="item.number"
                             :value="item.id">
                  </el-option>
                </el-select>-->
        <remote-search-input
          v-model="newIncidentId"
          searchUrl="/api/Incidents/GetIncidentDictionary"
          :start-length=3
          return-prop="id"
          :allow-create="false"
        />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeIncidentId = false">Отменить</el-button>
        <el-button type="primary" @click="updateEvent()">Подтвердить</el-button>
      </span>
    </el-dialog>
    <event-mapping-modal :my-store="this.myModalStore"></event-mapping-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import incidentsApi from '@/api/eventsAndIncidents/incidents/';
import eventsApi from '@/api/eventsAndIncidents/events/';
import remoteSearchInput from '@/components/Ui/RemoteSearchInput';
import EventMappingModalStore from '@/views/EventsAndIncidents/EventMapping/EventMappingModalStore';
import EventMappingModal from '@/views/EventsAndIncidents/EventMapping/EventMappingModal.vue';

export default {
  name: 'EventGeneral',
  props: {
    id: {
      type: String,
      default: () => ''
    },
    eventCard: {
      type: Object,
      default: () => {
      }
    },
    estimationsBlockTech: {
      type: Array,
      default: () => []
    },
  },
  mixins: [formatters, dateHelpers],
  components: { EventMappingModal, remoteSearchInput },
  created() {
    this.myModalStore = new EventMappingModalStore(this.userHasRoleGroup('ExternalUser'), { toUserDate: this.toUserDate, toServerUtcDate: this.toServerUtcDate });
  },
  data() {
    const eventCardDateValidator = (rule, value, callback) => {
      const minDate = new Date(2012, 0);
      const currentDate = new Date();
      if (this.eventDate !== null && this.eventDate < minDate) {
        return callback(new Error('Дата не должна быть старше 01.01.2012'));
      } else if (this.eventDate > currentDate) {
        return callback(new Error('Дата не должна быть больше текущей'));
      } else if (this.eventDate == null) {
        return callback(new Error('Поле обязательно для заполнения'));
      } else {
        return callback();
      }
    };
    return {
      eventMap: [],
      changeIncidentId: false,
      editMode: false,
      loading: false,
      newIncidentId: '',
      incidentsList: [],
      rules: {
        eventDateTime: [
          {
            validator: eventCardDateValidator,
            trigger: 'blur',
            required: true,
          },
        ],
      },
      faultedPhasesLimit: 1
    };
  },
  mounted() {
    this.$root.$on('editEvent', (x) => {
      this.editMode = x.editMode;
    });

    // this.getDictionary();
  },
  computed: {
    ...mapGetters('dictionaries', ['cardInformations', 'shortCircuitForms', 'shortCircuitPlaces', 'faultedPhases', 'eventTypes']),
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    eventDate: {
      get() {
        return this.dateGetter(this.eventCard.eventTime);
      },
      set(val) {
        // this.onChangeFaultedPhases();
        this.eventCard.eventTime = this.dateSetter(val);
      },
    },
    showEventMapMsg() {
      return this.eventMap && this.eventMap.length > 0;
    },
    isExternal() {
      return this.userHasRoleGroup('ExternalUser');
    }
  },
  methods: {
    onChangeFaultedPhases() {
      if (this.eventCard.shortCircuitFormId === 2 || this.eventCard.shortCircuitFormId === 3) {
        this.faultedPhasesLimit = 1;
      } else if (this.eventCard.shortCircuitFormId === 4 || this.eventCard.shortCircuitFormId === 6) {
        this.faultedPhasesLimit = 2;
      } else if (this.eventCard.shortCircuitFormId === 5) {
        this.eventCard.faultedPhases = [];
        this.faultedPhasesLimit = 3;
        this.eventCard.faultedPhases.push(...[1, 2, 3]);
      } else this.faultedPhasesLimit = this.faultedPhases.length;
    },

    async getDictionary() {
      if (this.eventCard.incidentNumber) {
        const res = await incidentsApi.getIncidentDictionary();
        if (res.data) {
          this.incidentsList = res.data;
        }
      }
    },
    async updateEvent() {
      const event = {
        eventId: this.eventCard.id,
        incidentId: this.newIncidentId,
      };
      await eventsApi.updateEventIncidentId(event)
        .then(() => {
          this.changeIncidentId = false;
          this.$router.go();
        });
    },
      async handleOpenCor(eventCorrelationId) {
        await this.myModalStore.openByIdAsync(eventCorrelationId);
      }
  },
};
</script>

<style scoped lang="scss">
.el-dialog__wrapper.el-dialog.el-dialog__body {
  padding: 20px;
}

.el-radio-group {
  font-size: initial !important;
}

.el-radio-group {
  width: 100%;
}

span.el-radio__label {
  padding-left: 5px;
}

span.el-radio__label > span {
  padding: 0px 5px;
}

span.el-radio__inner {
  margin: 0px 0px 9px 0px;
}

.el-tabs__header.is-top {
  margin: 0px !important;
}

button.el-button.el-button--text:first-child {
  margin-left: 0px;
}

.el-col > span {
  font-size: 12px;
}
/*button.el-button.el-button--text:hover {
  color: #3078bf;
}*/
.el-select {
  width: 100%;
}

span {
  //white-space: nowrap;
  vertical-align: middle;
}

.dialog-footer {
  text-align: center;
}

.scrollableTree {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 420px;
}

.tree-item > span > i {
  margin: 0px 5px 0px 0px;
}

.technical-reason--tree {
  font-size: 12px;

  .node__content {
    height: auto;
  }

  .custom-tree-node {
    .tree-item > span {
      white-space: normal;
    }

    .tree-item > span:last-of-type {
      line-height: 2rem;
    }

    .tree-item {
      display: inline-flex;
      align-content: center;
    }

  }
}
</style>
