<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="90%"
    height="100%"
    center
    fullscreen
    z-index="999"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Функции РЗА
    </div>
    <div class="m-2">
      <div style="display: inline-block;">
<!--        <span class="mr-4">Отобразить:</span>-->
        <el-checkbox @change="applyFilter" v-model="filter.showSuccess">Успешно сопоставленные</el-checkbox>
        <el-checkbox @change="applyFilter" v-model="filter.showConflict">Конфликтные</el-checkbox>
        <el-checkbox @change="applyFilter" v-model="filter.showNew">Новые</el-checkbox>
        <el-checkbox @change="applyFilter" v-model="filter.showDivergence">Расхождение</el-checkbox>
      </div>
      <div style="display: inline-block; float: right">
        <el-button :disabled="ignoreMappingDisabled() || !this.allowMatch" class="p-0 mr-2" @click="ignoreMapping" type="text" icon="el-icon-circle-close">Игнорировать</el-button>
        <el-button :disabled="matchManuallyDisabled() || this.reviewStatus == 2 || !this.allowMatch" class="p-0 mr-2" @click="matchManually" type="text" icon="el-icon-connection">Сопоставить вручную</el-button>
        <el-button :disabled="breakMappingDisabled() || this.reviewStatus == 2 || !this.allowMatch" class="p-0 mr-2" @click="breakMapping" type="text" icon="el-icon-remove-outline">Разорвать сопоставление</el-button>
      </div>
    </div>
    <el-table style="width: 100%;"
              class="compare-modal-table"
              v-loading="loading"
              :data="filteredItems"
              :row-class-name="getRowClassName"
              height="70vh"
              @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="this.reviewStatus !== 2 && this.allowMatch"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="matchStatus"
        label=""
        width="50" fixed>
        <template slot-scope="scope">
          <div style="text-align: center">
            <i v-if="scope.row.matchStatus === matchStatuses.divergence"
               style="font-size: 1.4rem"
               class="event-compare-divergence">
               <QuestionIcon/>
            </i>
            <i v-else-if="scope.row.matchStatus === matchStatuses.conflict"
               style="font-size: 1.4rem"
               class="el-icon-warning-outline event-compare-conflict" />
            <i v-else-if="scope.row.matchStatus === matchStatuses.newRow"
               style="font-size: 1.4rem"
               class="el-icon-circle-plus-outline event-compare-new" />
            <i v-else-if="scope.row.matchStatus === matchStatuses.success"
               style="font-size: 1.4rem"
               class="el-icon-circle-check event-compare-success" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Номер карточки" width="200" fixed>
        <el-table-column
          prop="number"
          :label="publicLabel" width="100" fixed>
          <template slot-scope="scope">
            <span v-if="scope.row.publicFunctionCard">
              {{ scope.row.publicFunctionCard.number }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="number"
          :label="userLabel" width="100" fixed>
          <template slot-scope="scope">
            <span v-if="scope.row.userFunctionCard">
              {{ scope.row.userFunctionCard.number }}
            </span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Функция РЗА" width="500" fixed>
        <el-table-column
          prop="publicSecondEquipment"
          :label="publicLabel" width="250">
          <template slot-scope="scope">
            <div v-if="scope.row.publicFunctionCard">
              <span class="mb-1" :class="[ getAnalysisIdsMatchClass(scope.row) ]">
                <template v-for="(analysisId, $index) in scope.row.publicFunctionCard.secondEquipmentAnalysisIds">
                  <span :key="$index">
                    {{ analysisId }};
                  </span>
                </template>
                <span v-if="scope.row.publicFunctionCard.secondEquipmentAnalysisIds.length===0">---</span>
              </span>
              <hr />
              <span :class="[ getParamMatchClass(scope.row, 'secondEquipmentName') ]">
                {{ scope.row.publicFunctionCard.secondEquipmentName }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="userSecondEquipment"
          :label="userLabel" width="250">
          <template slot-scope="scope">
            <div v-if="scope.row.userFunctionCard">
              <span class="mb-1" :class="[ getAnalysisIdsMatchClass(scope.row) ]">
                <template v-for="(analysisId, $index) in scope.row.userFunctionCard.secondEquipmentAnalysisIds">
                  <span :key="$index">
                    {{ analysisId }};
                  </span>
                </template>
                <span v-if="scope.row.userFunctionCard.secondEquipmentAnalysisIds.length===0">---</span>
              </span>
              <hr />
              <span :class="[ getParamMatchClass(scope.row, 'secondEquipmentName') ]">
                {{ scope.row.userFunctionCard.secondEquipmentName }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Оценка" min-width="200">
        <template
              slot="header">
              <el-popover
                ref="fromPopOverMark"
                placement="top-start"
                width="250"
                trigger="hover">
                <span class="manual-match-tooltip">
                Для выбора данных, которые требуется перенести в Систему, необходимо нажать левой кнопкой мышки на нужное значение. При акцепте будет произведена запись параметров, текст которых выделен полужирным. Значения, выделенные серым не будут записаны в Систему.
                </span>
              </el-popover>
              <span>Оценка <i
                v-if="!fromRelatedEvents"
                v-popover:fromPopOverMark
                class="el-icon-info
                text-blue" />
              </span>
            </template>
        <el-table-column
          prop="publicEstimationCode"
          :label="publicLabel" min-width="100">
          <template slot-scope="scope">
            <div
              v-if="scope.row.publicFunctionCard"
              @click="denySingleManualMatch(scope.row, 'estimationCode')"
              :class="[ getParamMatchClass(scope.row, 'estimationCode'), getPublicSingleManualMatchClass(scope.row, 'estimationCode')]"
            >
              <template v-if="scope.row.estimationCodeManualMatched">
                {{ scope.row.backupData.estimationCode }}
              </template>
              <template v-else>
                {{ scope.row.publicFunctionCard.estimationCode }}
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="userEstimationCode"
          :label="userLabel" min-width="100" class-name="manual-match">
          <template slot-scope="scope">
            <div
              v-if="scope.row.userFunctionCard"
              @click="applySingleManualMatch(scope.row, 'estimationCode')"
              :class="[ getParamMatchClass(scope.row, 'estimationCode'), getUserSingleManualMatchClass(scope.row, 'estimationCode') ]"
            >
              {{ scope.row.userFunctionCard.estimationCode }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="staffCulpabilityMatches"
                       label="Организационные причины"
                       min-width="150">
        <template slot-scope="scope">
            <span class="span-link"
                  v-if="scope.row.staffCulpabilityMatchList.length > 0"
                  :class="getFStaffCulpabilityClassByStatusAndReview(scope.row)"
                  @click="showStaffCulpabilityMatchModal(scope.row)">
              {{ getTextByMatchStatus(scope.row.staffCulpabilitiesMatchStatus) }}
            </span>
        </template>
      </el-table-column>
      <el-table-column label="Технические причины" min-width="200">
        <template
              slot="header">
              <el-popover
                ref="fromPopOverTech"
                placement="top-start"
                width="250"
                trigger="hover">
                <span class="manual-match-tooltip">
                Для выбора данных, которые требуется перенести в Систему, необходимо нажать левой кнопкой мышки на нужное значение. При акцепте будет произведена запись параметров, текст которых выделен полужирным. Значения, выделенные серым не будут записаны в Систему.
                </span>
              </el-popover>
              <span>Технические причины <i
                v-if="!fromRelatedEvents"
                v-popover:fromPopOverTech
                class="el-icon-info
                text-blue" />
              </span>
            </template>
        <el-table-column
          prop="publicTechnicalReasons"
          :label="publicLabel" min-width="100">
          <template slot-scope="scope">
            <div
              v-if="scope.row.publicFunctionCard"
              @click="denySingleManualMatch(scope.row, 'technicalReasonMatchList')"
              :class="[ getTechnicalReasonsMatchClass(scope.row), getPublicSingleManualMatchClass(scope.row, 'technicalReasonMatchList')]"
            >
              <template v-if="scope.row.technicalReasonMatchListManualMatched">
                <template v-for="(match, $index) in scope.row.backupData.technicalReasonMatchList">
                  <span :key="$index" v-if="match.publicTechnicalReasonText">
                    {{ match.publicTechnicalReasonText }};
                  </span>
                </template>
              </template>
              <template v-else>
                <template v-for="(match, $index) in scope.row.technicalReasonMatchList">
                  <span :key="$index" v-if="match.publicTechnicalReasonText">
                    {{ match.publicTechnicalReasonText }};
                  </span>
                </template>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="userTechnicalReasons"
          :label="userLabel" min-width="100" class-name="manual-match">
          <template slot-scope="scope">
            <div
              @click="applySingleManualMatch(scope.row, 'technicalReasonMatchList')"
              :class="[ getTechnicalReasonsMatchClass(scope.row), getUserSingleManualMatchClass(scope.row, 'technicalReasonMatchList') ]"
            >
              <template v-for="(match, $index) in scope.row.technicalReasonMatchList">
                <span :key="$index" v-if="match.userTechnicalReasonText">
                  {{ match.userTechnicalReasonText }};
                </span>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Доп. информация" min-width="200">
        <template
              slot="header">
              <el-popover
                ref="fromPopOverAddit"
                placement="top-start"
                width="250"
                trigger="hover">
                <span class="manual-match-tooltip">
                Для выбора данных, которые требуется перенести в Систему, необходимо нажать левой кнопкой мышки на нужное значение. При акцепте будет произведена запись параметров, текст которых выделен полужирным. Значения, выделенные серым не будут записаны в Систему.
                </span>
              </el-popover>
              <span>Доп. информация <i
                v-if="!fromRelatedEvents"
                v-popover:fromPopOverAddit
                class="el-icon-info
                text-blue" />
              </span>
            </template>
        <el-table-column
          prop="publicCardInformation"
          :label="publicLabel" min-width="100">
          <template slot-scope="scope">
            <div
              v-if="scope.row.publicFunctionCard"
              @click="denySingleManualMatch(scope.row, 'cardInformationMatchList')"
              :class="[ getCardInformationMatchClass(scope.row), getPublicSingleManualMatchClass(scope.row, 'cardInformationMatchList')]"
            >
              <template v-if="scope.row.cardInformationMatchListManualMatched">
                <template v-for="(match, $index) in scope.row.backupData.cardInformationMatchList">
                  <span :key="$index" v-if="match.publicCardInformationText">
                    {{ match.publicCardInformationText }};
                  </span>
                </template>
              </template>
              <template v-else>
                <template v-for="(match, $index) in scope.row.cardInformationMatchList">
                  <span :key="$index" v-if="match.publicCardInformationText">
                    {{ match.publicCardInformationText }};
                  </span>
                </template>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="userCardInformation"
          :label="userLabel" min-width="100" class-name="manual-match">
          <template slot-scope="scope">
            <div
              @click="applySingleManualMatch(scope.row, 'cardInformationMatchList')"
              :class="[ getCardInformationMatchClass(scope.row), getUserSingleManualMatchClass(scope.row, 'cardInformationMatchList') ]"
            >
              <template v-for="(match, $index) in scope.row.cardInformationMatchList">
                <span :key="$index" v-if="match.userCardInformationText">
                  {{ match.userCardInformationText }};
                </span>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Объем УВ" min-width="200">
        <template
              slot="header">
              <el-popover
                ref="fromPopOverUV"
                placement="top-start"
                width="50"
                trigger="hover">
                <span class="manual-match-tooltip">
                Для выбора данных, которые требуется перенести в Систему, необходимо нажать левой кнопкой мышки на нужное значение. При акцепте будет произведена запись параметров, текст которых выделен полужирным. Значения, выделенные серым не будут записаны в Систему.
                </span>
              </el-popover>
              <span>Объем УВ <i
                v-if="!fromRelatedEvents"
                v-popover:fromPopOverUV
                class="el-icon-info
                text-blue" />
              </span>
            </template>
        <el-table-column
          prop="publicControlActionCapacity"
          :label="publicLabel" min-width="100"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.publicFunctionCard"
              @click="denySingleManualMatch(scope.row, 'controlActionCapacity')"
              :class="[ getParamMatchClass(scope.row, 'controlActionCapacity'), getPublicSingleManualMatchClass(scope.row, 'controlActionCapacity')]"
            >
              <template v-if="scope.row.controlActionCapacityManualMatched">
                {{ scope.row.backupData.controlActionCapacity }}
              </template>
              <template v-else>
                {{ scope.row.publicFunctionCard.controlActionCapacity }}
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="userControlActionCapacity"
          :label="userLabel" min-width="100" class-name="manual-match"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.userFunctionCard"
              @click="applySingleManualMatch(scope.row, 'controlActionCapacity')"
              :class="[ getParamMatchClass(scope.row, 'controlActionCapacity'), getUserSingleManualMatchClass(scope.row, 'controlActionCapacity') ]"
            >
              {{ scope.row.userFunctionCard.controlActionCapacity }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>

      <template v-if="fromRelatedEvents">
        <el-table-column label="Детализированная причина неправильной работы" min-width="400">
          <el-table-column prop="publicMisoperationReasons"
                           label="СО ЕЭС" min-width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.publicFunctionCard">
                {{ scope.row.publicFunctionCard.misoperationReasonsText }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userMisoperationReasons"
                           label="Субъект" min-width="200">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userFunctionCard.misoperationReasonsText }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="Описание причины" min-width="400">
          <el-table-column prop="publicDescription"
                           label="СО ЕЭС" min-width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.publicFunctionCard">
                {{ scope.row.publicFunctionCard.description }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="userDescription"
                           label="Субъект" min-width="200">
            <template slot-scope="scope">
              <span>
                {{ scope.row.userFunctionCard.description }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
      </template>

    </el-table>

    <staff-culpability-match-modal
      v-if="staffCulpabilityMatchModalVisible"
      v-model="staffCulpabilityMatchModalVisible"
      :items="matchList"
      :review-status="selectedItem.reviewStatus"
      @match-status-changed="handleStaffCulpabilityMatchStatusChange"
      @all-ignore-handle="selectedItem.staffCulpabilitiesReviewStatus = reviewStatuses.ignoredNotAccepted"
      @not-all-ignore-handle="selectedItem.staffCulpabilitiesReviewStatus = reviewStatuses.notReviewed"
    />
  </el-dialog>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import eventCompare from '@/mixins/eventCompare';
import QuestionIcon from '@/assets/icons/question-icon.svg';
import StaffCulpabilityMatchModal
  from '@/views/EventsAndIncidents/EventsMatch/StaffCulpabilityMatchModal';

export default {
  name: 'FunctionCardsMatchModal',
  props: ['value', 'items', 'reviewStatus', 'allowMatch', 'fromRelatedEvents'],
  components: { StaffCulpabilityMatchModal, QuestionIcon },
  mixins: [eventCompare],
  data() {
    return {
      loading: false,
      filter: {
        showSuccess: true, showConflict: true, showDivergence: true, showNew: true
      },
      filteredItems: [],
      selectedItems: [],
      staffCulpabilityMatchModalVisible: false,
      selectedItem: null,
      matchList: []
    };
  },
  mounted() {
    this.filteredItems = this.items;
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    publicLabel() {
      return this.fromRelatedEvents ? 'СО ЕЭС' : 'Текущее';
    },
    userLabel() {
      return this.fromRelatedEvents ? 'Субъект' : 'Импорт';
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    getParamMatchClass(row, param) {
      if (!row.publicFunctionCard) {
        return 'event-compare-new';
      }
      if (!row.userFunctionCard) {
        return 'event-compare-divergence';
      }
      if (!row.userFunctionCard[param] && !row.publicFunctionCard[param]) {
        return '';
      }
      if (row.userFunctionCard[param] !== row.publicFunctionCard[param]) {
        return 'event-compare-conflict';
      }

      return '';
    },
    getAnalysisIdsMatchClass(row) {
      if (!row.publicFunctionCard) {
        return 'event-compare-new';
      }
      if (!row.userFunctionCard) {
        return 'event-compare-divergence';
      }
      if (((!row.userFunctionCard.secondEquipmentAnalysisIds) || (row.userFunctionCard.secondEquipmentAnalysisIds.length === 0)) && ((!row.publicFunctionCard.secondEquipmentAnalysisIds) || (row.publicFunctionCard.secondEquipmentAnalysisIds.length === 0))) {
        return '';
      }
      if (((!row.userFunctionCard.secondEquipmentAnalysisIds) || (row.userFunctionCard.secondEquipmentAnalysisIds.length === 0)) || ((!row.publicFunctionCard.secondEquipmentAnalysisIds) || (row.publicFunctionCard.secondEquipmentAnalysisIds.length === 0))) {
        return 'event-compare-conflict';
      }

      for (let i = 0; i < row.publicFunctionCard.secondEquipmentAnalysisIds.length; i++) {
        if (row.userFunctionCard.secondEquipmentAnalysisIds.some((x) => x === row.publicFunctionCard.secondEquipmentAnalysisIds[i])) {
          return '';
        }
      }
      return 'event-compare-conflict';
    },
    getTechnicalReasonsMatchClass(row) {
      if (!row.technicalReasonMatchList || row.technicalReasonMatchList.length === 0) {
        return '';
      }
      if (!row.userFunctionCard) {
        return 'event-compare-divergence';
      }
      if (!row.publicFunctionCard) {
        return 'event-compare-new';
      }
      if (row.technicalReasonMatchList.some((t) => !t.isMatch)) {
        return 'event-compare-conflict';
      }

      return '';
    },
    getCardInformationMatchClass(row) {
      if (!row.cardInformationMatchList || row.cardInformationMatchList.length === 0) {
        return '';
      }
      if (!row.userFunctionCard) {
        return 'event-compare-divergence';
      }
      if (!row.publicFunctionCard) {
        return 'event-compare-new';
      }
      if (row.cardInformationMatchList.some((t) => !t.isMatch)) {
        return 'event-compare-conflict';
      }

      return '';
    },
    applyFilter() {
      let tmp = this.items;

      if (!this.filter.showSuccess) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.success);
      }
      if (!this.filter.showDivergence) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.divergence);
      }
      if (!this.filter.showNew) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.newRow);
      }
      if (!this.filter.showConflict) {
        tmp = tmp.filter((r) => r.matchStatus !== this.matchStatuses.conflict);
      }

      this.filteredItems = tmp;
    },
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
    ignoreMappingDisabled() {
      if (this.reviewStatus !== this.reviewStatuses.notReviewed) {
        return true;
      }

      if (this.selectedItems.length === 0) {
        return true;
      }

      return false;
    },
    ignoreMapping() {
      this.selectedItems.forEach((selection) => {
        if (selection.reviewStatus === this.reviewStatuses.notReviewed) {
          this.$set(selection, 'reviewStatus', this.reviewStatuses.ignoredNotAccepted);
        } else if (selection.reviewStatus === this.reviewStatuses.ignoredNotAccepted) {
          this.$set(selection, 'reviewStatus', this.reviewStatuses.notReviewed);
        }

        selection.staffCulpabilityMatchList.forEach((st) => {
          this.$set(st, 'reviewStatus', selection.reviewStatus);
        });
      });

      if (this.selectedItems.every((t) => t.reviewStatus === this.reviewStatuses.ignoredNotAccepted)) {
        this.$emit('all-ignore-handle');
      }
      if (this.selectedItems.every((t) => t.reviewStatus === this.reviewStatuses.notReviewed)) {
        this.$emit('not-all-ignore-handle');
      }
    },
    breakMappingDisabled() {
      if (this.reviewStatus !== this.reviewStatuses.notReviewed) {
        return true;
      }

      if (this.selectedItems.length === 0) {
        return true;
      }

      const checkStatuses = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.conflict || t.matchStatus === this.matchStatuses.success);
      if (!checkStatuses) return true;

      return false;
    },
    breakMapping() {
      /* Разрыв сопоставления возможен для карточек срабатываний, имеющих состояние: «Успешно», «Конфликт» */
      if (this.selectedItems.length === 0) {
        return;
      }

      this.selectedItems.forEach((selection) => {
        const baseItem = this.items.find((i) => i === selection);
        if (!baseItem || !(baseItem.matchStatus === this.matchStatuses.conflict || baseItem.matchStatus === this.matchStatuses.success)) {
          return;
        }
        const idx = this.items.findIndex((i) => i === baseItem);

        const userItem = cloneDeep(this.items[idx]);
        const publicItem = cloneDeep(this.items[idx]);

        // одной записи очистить карточку юзера, а другой - карточку паблик
        userItem.userFunctionCard = null;
        userItem.staffCulpabilitiesMatchStatus = this.matchStatuses.divergence;
        userItem.staffCulpabilityMatchList.forEach((scMatch) => {
          scMatch.userStaffCulpability = null;
          scMatch.organizationNameManualMatched = false;
          scMatch.staffCategoryCodeManualMatched = false;
          scMatch.matchStatus = this.matchStatuses.divergence;
        });
        userItem.technicalReasonMatchList.forEach((tMatch) => {
          tMatch.userTechnicalReasonText = null;
          tMatch.isMatch = false;
        });
        userItem.cardInformationMatchList.forEach((tMatch) => {
          tMatch.userCardInformationText = null;
          tMatch.isMatch = false;
        });
        userItem.matchStatus = this.matchStatuses.divergence;

        publicItem.publicFunctionCard = null;
        publicItem.staffCulpabilitiesMatchStatus = this.matchStatuses.newRow;
        publicItem.staffCulpabilityMatchList.forEach((scMatch) => {
          scMatch.publicStaffCulpability = null;
          scMatch.organizationNameManualMatched = false;
          scMatch.staffCategoryCodeManualMatched = false;
          scMatch.matchStatus = this.matchStatuses.newRow;
        });
        publicItem.technicalReasonMatchList.forEach((tMatch) => {
          tMatch.publicTechnicalReasonText = null;
          tMatch.isMatch = false;
        });
        publicItem.cardInformationMatchList.forEach((tMatch) => {
          tMatch.publicCardInformationText = null;
          tMatch.isMatch = false;
        });
        publicItem.matchStatus = this.matchStatuses.newRow;

        // this.items.splice(itemIndex, 0, publicItem);
        this.items.splice(idx, 1);
        this.items.splice(idx, 0, userItem);
        this.items.splice(idx, 0, publicItem);
      });

      this.$emit('match-status-changed', this.recalculateMatchStatus(this.items));

      this.applyFilter();
    },
    matchManuallyDisabled() {
      if (this.reviewStatus !== this.reviewStatuses.notReviewed) {
        return true;
      }

      if (this.selectedItems.length !== 2) {
        return true;
      }
      const userItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.newRow);
      const publicItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.divergence);

      if (!userItem || !userItem.userFunctionCard || !publicItem || !publicItem.publicFunctionCard) return true;

      return false;
    },
    matchManually() {
      if (this.selectedItems.length !== 2) {
        return;
      }

      const userItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.newRow);
      const publicItem = this.selectedItems.find((t) => t.matchStatus === this.matchStatuses.divergence);

      if (!userItem || !userItem.userFunctionCard || !publicItem || !publicItem.publicFunctionCard) return;

      const staffCulpabilityMatchList = this.fullJoinStaffCulpabilities(userItem.userFunctionCard.staffCulpabilities,
        publicItem.publicFunctionCard.staffCulpabilities);

      const technicalReasonMatchList = this.fullJoinTechnicalReasons(userItem.userFunctionCard.technicalReasons,
        publicItem.publicFunctionCard.technicalReasons);

      const cardInformationMatchList = this.fullJoinCardInformation(userItem.userFunctionCard.cardInformation,
        publicItem.publicFunctionCard.cardInformation);

      const matchStatus = (
        publicItem.publicFunctionCard.secondEquipmentId !== userItem.userFunctionCard.secondEquipmentId
        || publicItem.publicFunctionCard.estimationId !== userItem.userFunctionCard.estimationId
        || publicItem.publicFunctionCard.controlActionCapacity !== userItem.userFunctionCard.controlActionCapacity
        || staffCulpabilityMatchList.some((t) => t.matchStatus === this.matchStatuses.conflict)
        || technicalReasonMatchList.some((t) => !t.isMatch)
        || cardInformationMatchList.some((t) => !t.isMatch)
      ) ? this.matchStatuses.conflict : this.matchStatuses.success;

      // пересчитать статус сопоставления орг причин
      const staffCulpabilitiesMatchStatus = this.сalculateStaffCulpabilityMatchStatus(staffCulpabilityMatchList);

      const newItem = {
        userFunctionCard: userItem.userFunctionCard,
        publicFunctionCard: publicItem.publicFunctionCard,
        staffCulpabilityMatchList,
        technicalReasonMatchList,
        cardInformationMatchList,
        matchStatus,
        staffCulpabilitiesMatchStatus,
        reviewStatus: this.reviewStatuses.notReviewed
      };

      const userIdx = this.items.findIndex((t) => t === userItem);
      this.items.splice(userIdx, 1);

      const publicIdx = this.items.findIndex((t) => t === publicItem);
      this.items.splice(publicIdx, 1);

      const newIdx = userIdx <= publicIdx ? userIdx : publicIdx;
      this.items.splice(newIdx, 0, newItem);

      this.$emit('match-status-changed', this.recalculateMatchStatus(this.items));

      this.applyFilter();
    },
    getPublicSingleManualMatchClass(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return '';
      }

      if (row[`${param}ManualMatched`]) {
        return 'manual-match-unselected';
      }
      return 'manual-match-selected';
    },
    getUserSingleManualMatchClass(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return '';
      }

      if (row[`${param}ManualMatched`]) {
        return 'manual-match-selected';
      }
      return 'manual-match-unselected';
    },
    // eslint-disable-next-line no-unused-vars
    canSingleManualMatch(row, param) {
      if (row.reviewStatus === this.reviewStatuses.reviewed || row.reviewStatus === this.reviewStatuses.ignored) {
        return false;
      }
      if (!row.publicFunctionCard) {
        return false;
      }
      if (!row.userFunctionCard) {
        return false;
      }
      return true;
    },
    applySingleManualMatch(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return;
      }

      if (row[`${param}ManualMatched`]) {
        return;
      }

      if (!row.backupData) {
        row.backupData = [];
      }

      if (param === 'estimationCode') {
        this.$set(row.backupData, 'estimationCode', row.publicFunctionCard.estimationCode);
        this.$set(row.backupData, 'estimationId', row.publicFunctionCard.estimationId);
      } else if (param === 'technicalReasonMatchList' || param === 'cardInformationMatchList') {
        this.$set(row.backupData, param, cloneDeep(row[param]));
      } else {
        this.$set(row.backupData, param, cloneDeep(row.publicFunctionCard[param]));
      }

      if (param === 'technicalReasonMatchList') {
        this.$set(row.backupData, 'publicTechnicalReasons', cloneDeep(row.publicFunctionCard.technicalReasons));
        row.publicFunctionCard.technicalReasons = cloneDeep(row.userFunctionCard.technicalReasons);

        row.technicalReasonMatchList = this.fullJoinTechnicalReasons(row.userFunctionCard.technicalReasons,
          row.publicFunctionCard.technicalReasons);
      } else if (param === 'cardInformationMatchList') {
        this.$set(row.backupData, 'publicCardInformation', cloneDeep(row.publicFunctionCard.cardInformation));
        row.publicFunctionCard.cardInformation = cloneDeep(row.userFunctionCard.cardInformation);

        row.cardInformationMatchList = this.fullJoinCardInformation(row.userFunctionCard.cardInformation,
          row.publicFunctionCard.cardInformation);
      } else {
        if (param === 'estimationCode') {
          row.publicFunctionCard.estimationCode = row.userFunctionCard.estimationCode;
          row.publicFunctionCard.estimationId = row.userFunctionCard.estimationId;
        } else if (param === 'controlActionCapacity') {
          row.publicFunctionCard.controlActionCapacity = row.userFunctionCard.controlActionCapacity;
        }
      }

      // console.log(row.backupData);

      this.$set(row, `${param}ManualMatched`, true);
      this.updateRowMatchStatus(row);
      this.applyFilter();
    },
    denySingleManualMatch(row, param) {
      if (!this.canSingleManualMatch(row, param)) {
        return;
      }

      if (!row[`${param}ManualMatched`]) {
        return;
      }

      if (param === 'estimationCode') {
        row.publicFunctionCard.estimationCode = row.backupData.estimationCode;
        row.publicFunctionCard.estimationId = row.backupData.estimationId;

        row.backupData.estimationCode = null;
        row.backupData.estimationId = null;
      } else {
        row.publicFunctionCard[param] = cloneDeep(row.backupData[param]);
        row.backupData[param] = null;
      }

      if (param === 'technicalReasonMatchList') {
        row.technicalReasonMatchList = this.fullJoinTechnicalReasons(row.userFunctionCard.technicalReasons,
          row.backupData.publicTechnicalReasons);

        this.$set(row.publicFunctionCard, 'technicalReasons', cloneDeep(row.backupData.publicTechnicalReasons));
        row.backupData.publicTechnicalReasons = null;
      } else if (param === 'cardInformationMatchList') {
        row.cardInformationMatchList = this.fullJoinCardInformation(row.userFunctionCard.cardInformation,
          row.backupData.publicCardInformation);

        this.$set(row.publicFunctionCard, 'cardInformation', cloneDeep(row.backupData.publicCardInformation));
        row.backupData.publicCardInformation = null;
      }

      // console.log(row.backupData);

      this.$set(row, `${param}ManualMatched`, false);
      this.updateRowMatchStatus(row);
      this.applyFilter();
    },
    updateRowMatchStatus(row) {
      if (!row.publicFunctionCard) {
        return;
      }
      if (!row.userFunctionCard) {
        return;
      }
      if (!row.staffCulpabilityMatchList) {
        return;
      }
      if (!row.technicalReasonMatchList) {
        return;
      }
      if (!row.cardInformationMatchList) {
        return;
      }
      if (
        row.publicFunctionCard.secondEquipmentId !== row.userFunctionCard.secondEquipmentId
        || row.publicFunctionCard.estimationId !== row.userFunctionCard.estimationId
        || row.publicFunctionCard.controlActionCapacity !== row.userFunctionCard.controlActionCapacity
        // || row.staffCulpabilityMatchList.some((t) => t.matchStatus === this.matchStatuses.conflict)
        || row.technicalReasonMatchList.some((t) => !t.isMatch)
        || row.cardInformationMatchList.some((t) => !t.isMatch)
      ) {
        row.matchStatus = this.matchStatuses.conflict;
      } else {
        if (row.staffCulpabilitiesMatchStatus === this.matchStatuses.divergence) row.matchStatus = this.matchStatuses.divergence;

        if (row.staffCulpabilitiesMatchStatus === this.matchStatuses.conflict) row.matchStatus = this.matchStatuses.conflict;

        if (row.staffCulpabilitiesMatchStatus === this.matchStatuses.success || row.staffCulpabilitiesMatchStatus === this.matchStatuses.undef) row.matchStatus = this.matchStatuses.success;

        if (row.staffCulpabilitiesMatchStatus === this.matchStatuses.newRow) row.matchStatus = this.matchStatuses.success;
      }

      this.$emit('match-status-changed', this.recalculateMatchStatus(this.items));
    },
    showStaffCulpabilityMatchModal(row) {
      this.selectedItem = row;
      this.matchList = row.staffCulpabilityMatchList;
      this.staffCulpabilityMatchModalVisible = true;
    },
    getFStaffCulpabilityClassByStatusAndReview(row) {
      let classByStatus = this.getClassByMatchStatus(row.staffCulpabilitiesMatchStatus);
      const classByIgnore = this.getClassByReviewStatus(row.staffCulpabilitiesReviewStatus);
      if (row.staffCulpabilitiesMatchStatus === this.matchStatuses.newRow && row.publicFunctionCard) {
        classByStatus = 'event-compare-conflict';
      }

      return `${classByStatus  } ${  classByIgnore}`;
    },
    handleStaffCulpabilityMatchStatusChange(staffCulpabilitiesMatchStatus) {
      this.selectedItem.staffCulpabilitiesMatchStatus = staffCulpabilitiesMatchStatus;
      this.isDataChanged = true;
      this.updateRowMatchStatus(this.selectedItem);
    },
  }
};
</script>

<style scoped lang="scss">
  svg {
    path{
      stroke: #e6a23c;
    }
  }

</style>
