<template>
  <div>
    <el-table
      :data="secondEquipmentTypeCardsList"
      v-loading="loading"
      style="width: 100%"
    >
      <el-table-column width="42px">
        <template slot-scope="props">
          <el-button type="text" icon="el-icon-search" @click="showModal(props.row.id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column width="42px">
        <template v-if="userHasRole('IncidentsEdit')" slot-scope="props">
          <el-button type="text" icon="el-icon-edit" @click="showEditModal(props.row.id)"></el-button>
        </template>
      </el-table-column>
      <el-table-column width="100" label="Номер карточки" prop="sortNumber"/>
      <el-table-column label="Комплекс РЗА" prop="secondEquipmentTypeName"></el-table-column>
      <el-table-column label="Оценка действия" prop="estimationCode" width="200"></el-table-column>
    </el-table>
    <second-equipment-type-card-modal
      v-model="dialogVisible"
      :eventCard="eventCard"
      :secondEquipmentTypeCard="secondEquipmentTypeCardItem"
      :edit-mode="modalEditMode"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import secondEquipmentTypeCardsApi from '@/api/eventsAndIncidents/secondEquipmentTypeCards';
import SecondEquipmentTypeCardModal from '@/views/EventsAndIncidents/Events/Tabs/SecondEquipmentTypeCardModal';

export default {
  name: 'EventSecondEquipmentTypeCards',
  props: {
    id: { type: String, default: () => '' },
    eventCard: { type: Object, default: () => {} },
    secondEquipmentTypeCardsList: { type: Array, default: () => {} },
    getData: { type: Function, default: () => {} },
  },
  components: {
    SecondEquipmentTypeCardModal,
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val === false) {
            this.loading = true;
            this.getData(this.eventCard.id).then(() => { this.loading = false; });
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      secondEquipmentTypeCardItem: {},
      dialogVisible: false,
      modalEditMode: false,
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
  },
  methods: {
    async showModal(id) {
      const res = await secondEquipmentTypeCardsApi.getSecondEquipmentTypeCard(id);
      this.secondEquipmentTypeCardItem = res.data;
      this.modalEditMode = false;
      this.dialogVisible = true;
    },
    async showEditModal(id) {
      const res = await secondEquipmentTypeCardsApi.getSecondEquipmentTypeCard(id);
      this.secondEquipmentTypeCardItem = res.data;
      this.modalEditMode = true;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
  .el-dialog__wrapper.el-dialog.el-dialog__body{
    padding: 20px;
  }
  .el-radio-group {
      font-size: initial !important;
  }
  .el-radio-group {
    width: 100%;
  }
  span.el-radio__label {
    padding-left: 5px;
  }
  span.el-radio__label>span {
    padding: 0px 5px;
  }
  span.el-radio__inner {
    margin: 0px 0px 9px 0px;
  }
  .el-tabs__header.is-top {
    margin: 0px !important;
  }
  /*button.el-button.el-button--text {
    color: #303133;
    margin: 0px 15px;
  }*/
  button.el-button.el-button--text:first-child {
    margin-left: 0px;
  }
  .el-col>span {
    font-size: 12px;
  }
  /*button.el-button.el-button--text:hover {
    color: #3078bf;
  }*/
  .el-select {
    width: 100%;
  }
  span {
    //white-space: nowrap;
    vertical-align: middle;
  }
  .dialog-footer {
    text-align: center;
  }
  .scrollableTree {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 420px;
  }
  .tree-item>span>i {
    margin: 0px 5px 0px 0px;
  }
  .technical-reason--tree{
    font-size: 12px;
    .node__content {
      height: auto;
    }
    .custom-tree-node{
      .tree-item > span {
      white-space: normal;
      }
    }
  }
</style>
